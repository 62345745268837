import React, {
  useEffect, useState 
} from 'react'

import styled from 'styled-components'
import optionImg from 'src/images/option-icon.svg'
import arrowImg from 'src/images/icons/arrow-right.png'
import { BackButton } from 'src/features/appointment/BackButton'
import { SearchInput } from 'src/features/appointment/SearchInput'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getCategoriesSelector } from 'src/features/appointment/selectors'
import {
  appointmentActions, fetchCategories, goToNextStep 
} from 'src/features/appointment/appointmentSlice'
import { TopRow } from 'src/features/common/TopRow'

export const CategoriesPage = ({ className = '' }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const categories = getCategoriesSelector()

  const handleOptionClick = (category) => {
    dispatch(appointmentActions.updateCurrentData({ category }))
    dispatch(goToNextStep({ navigate }))
  }

  useEffect(() => {
    dispatch(fetchCategories())
  }, [])

  const [search, setSearch] = useState('')

  const handleSearch = (search) => {
    setSearch(search)
  }

  return (
    <>
      <TopRow>
        <StyledSearchInput onSearch={handleSearch} />
      </TopRow>

      <CategoriesPageBox className={className}>

        <Title>
        Выбор услуги 
        </Title>

        {categories.filter(category => category.attributes.name.toLowerCase().includes(search)).map(category => (
          <OptionRow
            key={category.id}
            onClick={() => handleOptionClick(category)}
          >
            <OptionImg src={category.attributes.logo.data.attributes.url} />

            <OptionName>{category.attributes.name}</OptionName>

            <ArrowImg src={arrowImg} />
          </OptionRow>
        ))}
      </CategoriesPageBox>
    </>
  )
}

const CategoriesPageBox = styled.div`
  min-height: 100vh;
  padding: 16px 0 110px;
`

const Title = styled.p`
  margin: 0 0 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: ${props => props.theme.text.main};
  padding-left: 16px;
`

const OptionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 87px;
  margin-left: 16px;

  & ~ & {
    border-top: 0.5px solid #C6C6C6;
  }
`

const OptionImg = styled.img`
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 50%;
`

const OptionName = styled.p`
  margin-left: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: ${props => props.theme.text.main};
`

const ArrowImg = styled.img`
  margin-left: auto;
  width: 7px;
  height: 12px;
  margin-right: 16px;
`

const StyledSearchInput = styled(SearchInput)`
  margin: 0 12px;
`