/* eslint-disable max-len */

import { request } from './request'

export const appointmentApi = {
  getAvailableSteps: () => request('GET', '/start-setting'),
  getMainBanner: () => request('GET', '/banner-setting?populate=image&populate=category'),
  getCities: () => request('GET', '/cities'), 
  getBranches: ({ cityId }) => request('GET', `/branches/?pagination[start]=0&pagination[limit]=300&filters[city][id]=${cityId}`), 
  getServices: ({
    categoryId, cityId 
  }) => request('GET', `/services?pagination[start]=0&pagination[limit]=300&filters[masters][branch][city][id]=${cityId}&filters[categories][id]=${categoryId}`), 
  getMasters: ({ branchId }) => request('GET', `/masters?pagination[start]=0&pagination[limit]=300&filters[branch][id]=${branchId}`), 
  getCategories: ({ branchId }) => request('GET', `/categories?main=true&branch=${branchId}`),
  getRecords: ({
    masterId, services 
  }) => {
    const params = { services }

    if (masterId) {
      params.master = masterId
    }

    return request('GET', '/records/findFree', params)
  },
  createRecord: (data) => request('POST', '/records', { data }),
  getUserRecords: ({ userId }) => request('GET', '/records/my', { userId }),
  cancelRecord: (recordId) => request('DELETE', `/records/${recordId}`)
}