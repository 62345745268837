import React, {
  useEffect, useState 
} from 'react'

import styled from 'styled-components'
import emptyImg from 'src/images/empty-bg.svg'
import plusCircleWhiteImg from 'src/images/icons/plus-circle-white.svg'
import plusCircleBlackImg from 'src/images/icons/plus-circle-black.svg'
import { useNavigate } from 'react-router-dom'
import {
  getStepsSelector, getUserRecordsSelector 
} from 'src/features/appointment/selectors'
import { useDispatch } from 'react-redux'
import {
  appointmentActions,
  cancelRecord, fetchUserRecords, moveOrder, repeatOrder 
} from 'src/features/appointment/appointmentSlice'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import { DialogModal } from 'src/features/common/DialogModal'
import { appointmentApi } from 'src/features/api'

export const HomePage = ({ className = '' }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const steps = getStepsSelector()
  const records = getUserRecordsSelector()

  const [cancelModalData, setCancelModalData] = useState(null)

  const handleCreateAppointment = () => {
    dispatch(appointmentActions.updateCurrentData({ type: 'new' }))
    navigate(steps[0].url)
  }

  const handleCancelRecord = async () => {
    await appointmentApi.cancelRecord(cancelModalData.id)

    setCancelModalData(data => ({
      ...data, success: true 
    }))

    dispatch(fetchUserRecords())
  }

  useEffect(() => {
    dispatch(fetchUserRecords())
  }, [])

  const handleCancelOrderClick = (id) => {
    setCancelModalData({
      id,
      success: false
    })
  }

  const handleRepeatOrder = (record) => {
    dispatch(repeatOrder({
      record, navigate 
    }))
  }

  const handleMoveOrder = (record) => {
    dispatch(moveOrder({
      record, navigate
    }))
  }

  const handleCloseCancelModal = () => {
    setCancelModalData(null)
  }

  const currentRecords = records.filter(record => record.status !== 'completed')

  const completedRecords = records.filter(record => record.status === 'completed')

  return (
    <HomePageBox className={className}>
      <Title>Мои записи</Title>

      {!records.length &&
      <>
        <EmptyText>У вас пока нет записей</EmptyText>

        <EmptyImg src={emptyImg} />

        <AddBtn onClick={handleCreateAppointment}>
          <PlusImg src={plusCircleWhiteImg} />
          Добавить запись
        </AddBtn>
      </>
      }

      {!!records.length &&
      <>
        {currentRecords.map(record => (
          <Record
            key={record.id}
            record={record}
            onCancelClick={handleCancelOrderClick}
            onMoveClick={handleMoveOrder}
          />
        ))}

        {!!completedRecords.length &&
          <>
            <CompletedTitle>
              Прошедшие записи
            </CompletedTitle>

            {completedRecords.map(record => (
              <Record
                key={record.id}
                record={record}
                onCancelClick={handleCancelOrderClick}
                onRepeatClick={handleRepeatOrder}
                type={record.status}
              />
            ))}
          </>
        }
        
        <AddBtn
          onClick={handleCreateAppointment}
          className="secondary"
        >
          <PlusImg src={plusCircleBlackImg} />
          Добавить запись
        </AddBtn>
      </>
      }

      <DialogModal
        opened={!!cancelModalData}
        title="Отмена записи"
        text={!cancelModalData?.success ? 'После отмены запись будет невозможно восстановить' : 'Запись отменена'}
        okText="Закрыть"
        cancelText={!cancelModalData?.success ? 'Отменить запись' : ''}
        onOk={handleCloseCancelModal}
        onCancel={handleCancelRecord}
      />
    </HomePageBox>
  )
}

const Record = ({
  record, onCancelClick, onRepeatClick, onMoveClick, type 
}) => {
  const duration = record.services.reduce((r, e) => r += e.duration, 0)
  const amount = record.services.reduce((r, e) => r += e.price, 0)

  return (
    <OrderInfo key={record.id}>
      <OrderBranchInfo>
        <OrderBranchName>
          {record?.master?.branch.name}
        </OrderBranchName>

        <OrderBranchAddress>
          {record?.master?.branch.address}
        </OrderBranchAddress>
      </OrderBranchInfo>
      
      <OrderDate>
        {format(new Date(record.from), 'dd MMMM, HH:mm', { locale: ru })}
      </OrderDate>
      <OrderDescription>
        {record.services?.map((service, index) => (
          <React.Fragment key={service.name}>
            <p>
              {service.name}
            </p>
            {index !== record.services.length - 1 && <br />}
          </React.Fragment>
        ))}
      </OrderDescription>

      <OrderInfoRow>
        <OrderInfoRowLabel>
    Мастер:
        </OrderInfoRowLabel>

        <OrderInfoRowValue>
          {record?.master?.name}
        </OrderInfoRowValue>
      </OrderInfoRow>

      <OrderInfoRow>
        <OrderInfoRowLabel>
    Длительность:
        </OrderInfoRowLabel>

        <OrderInfoRowValue>
          {duration} мин
        </OrderInfoRowValue>
      </OrderInfoRow>

      <OrderInfoRow>
        <OrderInfoRowLabel>
    Стоимость:
        </OrderInfoRowLabel>

        <OrderInfoRowValue>
          {amount} ₽
        </OrderInfoRowValue>
      </OrderInfoRow>

      {type !== 'completed' &&
      <OrderBtnsBox>
        <OrderBtn onClick={() => onCancelClick(record.id)}>
          Отменить
        </OrderBtn>

        <OrderBtn
          onClick={() => onMoveClick(record)}
          className="primary"
        >
          Перенести
        </OrderBtn>
      </OrderBtnsBox>
      }

      {type === 'completed' &&
      <OrderBtnsBox>
        <OrderBtn
          onClick={() => onRepeatClick(record)}
          className="primary"
        >
          Повторить
        </OrderBtn>
      </OrderBtnsBox>
      }
    </OrderInfo>
  )}

const HomePageBox = styled.div`
  min-height: 100vh;
  padding: 16px 16px 110px;
  background: ${props => props.theme.backgrounds.secondary};
`

const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${props => props.theme.text.main};
`

const EmptyText = styled.p`
  margin-top: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${props => props.theme.text.quaternary};
`

const EmptyImg = styled.img`
  width: 100%;
  height: auto;
  max-width: 400px;
  margin: 40px auto;
`

const AddBtn = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.backgrounds.tertiary};
  color: ${props => props.theme.text.secondary};
  border-radius: 8px;

  &.secondary {
    background: ${props => props.theme.backgrounds.main};
    color: ${props => props.theme.text.main};
  }
`

const PlusImg = styled.img`
  margin-right: 16px;
  width: 24px;
  height: 24px;
`

const OrderInfo = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  background: ${props => props.theme.backgrounds.main};
  padding: 24px 16px;
  border-radius: 8px;

  & ~ & {
    margin-top: 24px;
  }
`

const OrderBranchInfo = styled.div`
  border-bottom: 0.5px solid #C6C6C6;
  margin-right: -16px;
  margin-bottom: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
`

const OrderBranchName = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: ${props => props.theme.text.main};
`

const OrderBranchAddress = styled.p`
  margin-top: 8px;
  color: ${props => props.theme.text.quaternary};
`

const OrderDate = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #037EE5;
`

const OrderDescription = styled.div`
  margin-top: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${props => props.theme.text.main};
`

const OrderInfoRow = styled.p`
  margin-top: 16px;
  display: flex;
  align-items: center;
`

const OrderInfoRowLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #7E7E82;
`

const OrderInfoRowValue = styled.span`
  margin-left: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: ${props => props.theme.text.main};

  &.blue {
    color: #037EE5;
  }
`

const OrderBtnsBox = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const OrderBtn = styled.button`
  width: 48%;
  height: 40px;
  background: #7E7E82;
  border: none;
  border-radius: 8px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.4px;
  color: #FFFFFF;

  &.primary {
    background: #037EE5;
  }
`

const CompletedTitle = styled.p`
  margin-bottom: 10px;
  margin-top: 32px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${props => props.theme.text.quaternary};
`