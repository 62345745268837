import React, {
  useEffect, useState 
} from 'react'

import styled from 'styled-components'
import optionImg from 'src/images/master.png'
import arrowImg from 'src/images/icons/arrow-right.png'
import { BackButton } from 'src/features/appointment/BackButton'
import { SearchInput } from 'src/features/appointment/SearchInput'
import { useDispatch } from 'react-redux'
import {
  appointmentActions, fetchMasters, goToNextStep 
} from 'src/features/appointment/appointmentSlice'
import { getMastersSelector } from 'src/features/appointment/selectors'
import { useNavigate } from 'react-router-dom'
import { TopRow } from 'src/features/common/TopRow'
import { Rating } from 'src/ui/Rating'

export const MastersPage = ({ className = '' }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const masters = getMastersSelector()

  const [search, setSearch] = useState('')

  const handleSearch = (search) => {
    setSearch(search)
  }

  const handleOptionClick = (master) => {
    dispatch(appointmentActions.updateCurrentData({ master }))
    dispatch(goToNextStep({ navigate }))
  }

  useEffect(() => {
    dispatch(fetchMasters())
  }, [])

  return (
    <>
      <TopRow>
        <StyledSearchInput onSearch={handleSearch} />
      </TopRow>

      <MastersPageBox className={className}>

        <Title>
        Мастера
        </Title>

        <AnyMasterRow onClick={() => handleOptionClick(null)}>
        Любой мастер
          <ArrowImg src={arrowImg} />
        </AnyMasterRow>

        {masters.filter(master => master.attributes.name.toLowerCase().includes(search)).map(master => (
          <OptionRow
            key={master.id}
            onClick={() => handleOptionClick(master)}
          >
            <OptionImg src={master.attributes.avatar.data.attributes.url} />

            <OptionMiddle>
              <OptionName>{master.attributes.name}</OptionName>

              <OptionDescription>
                {master.attributes.description}
              </OptionDescription>

              <OptionControls>
                <StyledRating rating={master.attributes?.reviews?.data?.averageReview} />

                <ReviewsCount>
                  {!!master.attributes?.reviews?.data?.reviewsCount &&
                    <ReviewsCount>{master.attributes?.reviews?.data?.reviewsCount}</ReviewsCount>
                  }
                  {!master.attributes?.reviews?.data?.reviewsCount &&
                    <ReviewsCount className='empty'>Нет отзывов</ReviewsCount>
                  }
                </ReviewsCount>
              </OptionControls>
            </OptionMiddle>

            <ArrowImg src={arrowImg} />
          </OptionRow>
        ))}
      </MastersPageBox>
    </>
  )
}

const MastersPageBox = styled.div`
  min-height: 100vh;
  padding: 16px 0 110px;
`

const Title = styled.p`
  margin: 0 0 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: ${props => props.theme.text.main};
  padding-left: 16px;
`

const OptionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 87px;
  margin-left: 16px;

  & ~ & {
    border-top: 0.5px solid #C6C6C6;
  }
`

const OptionImg = styled.img`
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 50%;
`

const OptionMiddle = styled.div`
  margin: 0 16px;
`

const OptionName = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: ${props => props.theme.text.main};
`

const OptionDescription = styled.p`
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #7E7E82;
`

const OptionControls = styled.div`
  display: flex;
  align-items: center;
`

const ArrowImg = styled.img`
  margin-left: auto;
  width: 7px;
  height: 12px;
  margin-right: 16px;
`

const StyledSearchInput = styled(SearchInput)`
  margin: 0 12px;
`

const AnyMasterRow = styled.div`
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: ${props => props.theme.text.main};
  border-bottom: 0.5px solid #C6C6C6;
`

const StyledRating = styled(Rating)`

`

const ReviewsCount = styled.div`
  margin-left: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #037EE5;
  text-decoration: underline;

  &.empty {
    text-decoration: none;
  }
`