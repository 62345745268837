import React, {
  useCallback, useState 
} from 'react'

import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/constants/routes'
import { debounce } from 'src/utils/debounce'

import { ReactComponent as SearchIcon } from '@/images/icons/search.svg'
import clearImg from '@/images/icons/close.png'

export const SearchInput = ({
  className = '', inputRef, isPlaceholder, onSearch 
}) => {
  const [value, setValue] = useState('')
  const navigate = useNavigate()

  const handleInputClick = () => {
    if (!isPlaceholder) {
      return
    }

    navigate(ROUTES.search)
  }

  const handleSearch = useCallback(debounce((search) => {
    onSearch(search)
  }, 0), [])

  const handleChange = (e) => {
    const value = e.target.value

    setValue(value)

    handleSearch(value)
  }

  const handleClearClick = () => {
    setValue('')

    handleSearch('')
  }


  return (
    <SearchInputBox
      className={className}
      onClick={handleInputClick}
    >
      <StyledSearchIcon />
      <Input
        placeholder='Поиск'
        ref={inputRef}
        value={value}
        onChange={handleChange}
      />

      {!!value &&
        <ClearImg
          src={clearImg}
          onClick={handleClearClick}
        />
      }
    </SearchInputBox>
  )
}

const SearchInputBox = styled.div`
  width: 100%;
  height: 32px;
  background: ${props => props.theme.inputs.background};
  border-radius: 8px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  position: relative;
`

const Input = styled.input`
  margin-left: 6px;
  height: 100%;
  flex: 1;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.2px;
  color: ${props => props.theme.inputs.color};
  border: none;
  outline: none;
  background: transparent;
  padding-right: 26px;

  &::placeholder {
    color: ${props => props.theme.inputs.placeholder};
  }
`

const StyledSearchIcon = styled(SearchIcon)`
  width: 14px;
  height: 14px;

  path {
    fill: ${props => props.theme.inputs.placeholder};
    fill-opacity: 1;
  }
`

const ClearImg = styled.img`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  transition: all 0.4s linear;
  
  &:hover {
    filter: brightness(110%);
  }
  
  &:focus, &:active {
    filter: brightness(120%);
  }
`