import React, { useEffect } from 'react'

import styled from 'styled-components'
import cityImg from 'src/images/city-banner.jpg'
import arrowImg from 'src/images/icons/arrow-right.png'
import {
  getCitiesSelector, getMainBanner 
} from 'src/features/appointment/selectors'
import { useDispatch } from 'react-redux'
import {
  appointmentActions, fetchCities, fetchMainBannerInfo, goToNextStep 
} from 'src/features/appointment/appointmentSlice'
import { useNavigate } from 'react-router-dom'

export const CityPage = ({ className = '' }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const cities = getCitiesSelector()
  const banner = getMainBanner()

  useEffect(async () => {
    dispatch(fetchMainBannerInfo())
    dispatch(fetchCities())
  }, [])

  const handleOptionClick = (city) => {
    dispatch(appointmentActions.updateCurrentData({ city }))
    dispatch(goToNextStep({ navigate }))
  }

  return (
    <>
      <CityPageBox className={className}>
        <Img src={banner?.image?.data?.attributes?.url} />

        <Title>
        Выбор города 
        </Title>

        {cities.map(city => (
          <OptionRow
            key={city.id}
            onClick={() => handleOptionClick(city)}
          >
            <OptionLeft>
              <CityName>
                {city.attributes.name}
              </CityName>
              <PointsCount>
              Филиалов: {city.attributes?.branches?.data?.length}
              </PointsCount>
            </OptionLeft>

            <ArrowImg src={arrowImg} />
          </OptionRow>
        ))}
      </CityPageBox>
    </>
  )
}

const CityPageBox = styled.div`
  min-height: 100vh;
  padding: 0 0 110px;
`

const Img = styled.img`
  width: 100%;
  height: auto;
`

const Title = styled.p`
  margin-top: 32px;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: ${props => props.theme.text.tertiary};
  padding-left: 16px;
`

const OptionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 16px;
  height: 72px;

  & ~ & {
    border-top: 0.33px solid rgba(60, 60, 67, 0.29);
  }
`

const OptionLeft = styled.div``

const CityName = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: ${props => props.theme.text.main};
`

const PointsCount = styled.p`
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #7E7E82;
`

const ArrowImg = styled.img`
  margin-right: 16px;
  width: 7px;
  height: 12px;
`