import { configureStore } from '@reduxjs/toolkit'
import { appointmentSlice } from 'src/features/appointment/appointmentSlice'

import { appReducer } from '../features/app/appSlice'

export const store = configureStore({
  reducer: {
    app: appReducer,
    appointment: appointmentSlice.reducer 
  }, 
})