import React from 'react'

import styled from 'styled-components'
import houseImg from 'src/images/icons/house.png'

import { BackButton } from '../appointment/BackButton'

export const TopRow = ({
  className = '', children, onBackButtonClick 
}) => {
  return (
    <TopRowBox className={className}>
      <BackButton onClick={onBackButtonClick} />

      {children}

      <a href="/">
        <House src={houseImg} />
      </a>
    </TopRowBox>
  )
}

const TopRowBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 12px 12px 10px;
`

const House = styled.img`
  width: 24px;
  height: 24px;
`