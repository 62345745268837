import React, { useState } from 'react'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createOrder } from 'src/features/appointment/appointmentSlice'
import { getCurrentAppointmentDataSelector } from 'src/features/appointment/selectors'
import { OrderBtn } from 'src/features/basket/BasketBtn'
import {
  InputGroupIOS, InputIOS 
} from 'src/ui/InputIOS'
import styled from 'styled-components'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import { TopRow } from 'src/features/common/TopRow'
import { getUserData } from 'src/features/app/selectors'

export const OrderPage = ({ className = '' }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  
  const data = getCurrentAppointmentDataSelector()
  const userDataFromStore = getUserData()

  const [userData, setUserData] = useState({
    name: userDataFromStore.name || '',
    phone: userDataFromStore.phone || ''
  })

  const handleCreateOrder = () => {
    dispatch(createOrder({
      userData, navigate 
    }))
  }

  const duration = data?.services?.reduce((r, elem) => (
    r += elem?.duration || elem?.attributes?.duration
  ), 0)

  const price = data?.services?.reduce((r, elem) => (
    r += elem?.price || elem?.attributes?.price
  ), 0)

  return (
    <>
      <TopRow>
        <Title>
          Онлайн запись 
        </Title>
      </TopRow>
      
      <OrderPageBox className={className}>
        <BranchInfo>
          <BranchImg src={data?.branch?.images?.[0].url || data?.branch?.attributes?.images?.data?.[0].attributes.url} />

          <BranchRight>
            <BranchName>
              {data?.branch?.name || data?.branch?.attributes?.name}
            </BranchName>

            <BranchDescription>
              {data?.branch?.address || data?.branch?.attributes?.address}
            </BranchDescription>
          </BranchRight>
        </BranchInfo>

        <InputGroupLabel>
        Ваши данные
        </InputGroupLabel>
        <InputGroupIOS>
          <InputIOS
            label="Имя"
            value={userData.name}
            onChange={(e) => {
              const regexp = /[^a-zа-яё_\-\/=\s]/gi
              const value = e.target.value
              setUserData(state => ({
                ...state,
                name: value.replace(regexp, '').substring(0, 25)
              }))}}
          />
          <InputIOS
            label="Телефон"
            value={userData.phone}
            mask="+79999999999"
            onChange={(e) => {
              const regexp = /[^0-9]/gi
              const value = e.target.value
              setUserData(state => ({
                ...state,
                phone: '+' + value.replace(regexp, '').substring(0, 11).trim()
              }))}}
          />
        </InputGroupIOS>

        <OrderInfo>
          <OrderDate>
            {format(new Date(data?.timeSlot?.from), 'dd MMMM, HH:mm', { locale: ru })}
          </OrderDate>
          <OrderDescription>
            {data?.services?.map(service => (
              <React.Fragment key={service.name || service.attributes.name}>
                <p>
                  {service.name || service.attributes.name}
                </p>
                <br />
              </React.Fragment>
            ))}
          </OrderDescription>

          <OrderInfoRow>
            <OrderInfoRowLabel>
            Мастер:
            </OrderInfoRowLabel>

            <OrderInfoRowValue>
              {data?.master?.name || data?.master?.attributes?.name}
            </OrderInfoRowValue>
          </OrderInfoRow>

          <OrderInfoRow>
            <OrderInfoRowLabel>
            Длительность:
            </OrderInfoRowLabel>

            <OrderInfoRowValue>
              {duration} мин
            </OrderInfoRowValue>
          </OrderInfoRow>

          <OrderInfoRow>
            <OrderInfoRowLabel>
            Стоимость:
            </OrderInfoRowLabel>

            <OrderInfoRowValue className='blue'>
              {price} ₽
            </OrderInfoRowValue>
          </OrderInfoRow>
        </OrderInfo>

        {/* <PriceInfo>
        <PriceRow>
          <span>
            Скидка
          </span>
          <span>
            0 ₽
          </span>
        </PriceRow>
        <PriceRow>
          <span>
          Стоимость
          </span>
          <span>
            1000-2000 ₽
          </span>
        </PriceRow>
      </PriceInfo> */}

        <Rules>
        Нажимая на кнопку «Сформировать заказ», я принимаю <a href="#">пользовательское соглашение</a> и <a href="#">политику конфиденциальности</a>
        </Rules>

        <OrderBtn
          text="Записаться"
          disabled={!userData.name || userData.phone.length < 12}
          onClick={handleCreateOrder}
        />
      </OrderPageBox>
    </>
  )
}

const OrderPageBox = styled.div`
  background: ${props => props.theme.backgrounds.secondary};
  padding: 16px 8px;
  min-height: 100vh;
  padding-bottom: 100px;
`

const BranchInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px;
  background: ${props => props.theme.backgrounds.main};
  border-radius: 8px 8px 0px 0px;
`

const BranchImg = styled.img`
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 50%;
`

const BranchRight = styled.div`
  margin-left: 24px;
`

const BranchName = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: ${props => props.theme.text.main};
`

const BranchDescription = styled.p`
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #7E7E82;
`

const OrderInfo = styled.div`
  margin-top: 24px;
  background: ${props => props.theme.backgrounds.main};
  padding: 24px 16px;
`

const OrderDate = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #037EE5;
`

const OrderDescription = styled.p`
  margin-top: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${props => props.theme.text.main};
`

const OrderInfoRow = styled.p`
  margin-top: 16px;
  display: flex;
  align-items: center;
`

const OrderInfoRowLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #7E7E82;
`

const OrderInfoRowValue = styled.span`
  margin-left: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: ${props => props.theme.text.main};

  &.blue {
    color: #037EE5;
  }
`

const PriceInfo = styled.div`
  margin-top: 8px;
  background: #FFFFFF;
  border-radius: 0px 0px 8px 8px;
  padding: 10px 0;
`

const PriceRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: ${props => props.theme.text.main};
  padding: 0 16px;

  & ~ & {
    border-top: 0.5px solid #C6C6C6;
  }
`

const InputGroupLabel = styled.p`
  margin-top: 28px;
  margin-bottom: 7px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.15px;
  text-transform: uppercase;
  color: #636366;
`

const Rules = styled.p`
  margin: 20px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.4px;
  color: ${props => props.theme.text.main};
  padding: 0 5px;

  a {
    color: ${props => props.theme.text.tertiary};
    text-decoration: underline;
  }
`

const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.2px;
  color: ${props => props.theme.text.main};
`