import {
  createSlice, createAsyncThunk 
} from '@reduxjs/toolkit'
import { ROUTES } from 'src/constants/routes'

import { appointmentApi } from '../api'
import { appActions } from '../app/appSlice'

const initialState = {
  userRecords: [],
  cities: [], 
  currentStep: 0,
  steps: [],
  branches: [],
  categories: [],
  services: [],
  masters: [],
  timeSlots: {},
  currentData: {},
  mainBanner: null
}

const STEPS = {
  region: { url: ROUTES.cities },
  branch: { url: ROUTES.branch },
  categories: { url: ROUTES.categories },
  services: { url: ROUTES.services },
  masters: { url: ROUTES.masters },
  calendar: { url: ROUTES.calendar },
  order: { url: ROUTES.order }
}

export const fetchMainBannerInfo = createAsyncThunk(
  'appointment/fetchMainBanner',
  async () => {
    const response = await appointmentApi.getMainBanner()
    
    return response.data?.attributes
  }
)

export const fetchUserRecords = createAsyncThunk(
  'appointment/fetchUserRecords',
  async () => {
    const response = await appointmentApi.getUserRecords({ userId: window?.Telegram?.WebApp?.initDataUnsafe?.user?.id || 3 })
    
    return response?.data || [] 
  }
)

export const cancelRecord = createAsyncThunk(
  'appointment/cancelRecord',
  async ({ id }, { dispatch }) => {
    await appointmentApi.cancelRecord(id)
    
    dispatch(fetchUserRecords(fetchUserRecords))
  }
)

export const fetchCities = createAsyncThunk(
  'appointment/fetchCities',
  async () => {
    const response = await appointmentApi.getCities()
    
    return response.data
  }
)

export const fetchBranches = createAsyncThunk(
  'appointment/fetchBranches',
  async (_, { getState }) => {
    const state = getState()

    const currentData = state.appointment.currentData

    const response = await appointmentApi.getBranches({ cityId: currentData.city.id })
    
    return response.data
  }
)

export const fetchCategories = createAsyncThunk(
  'appointment/fetchCategories',
  async (_, { getState }) => {
    const state = getState()

    const currentData = state.appointment.currentData

    const response = await appointmentApi.getCategories({ branchId: currentData.branch.id })
    
    return response.data
  }
)

export const fetchServices = createAsyncThunk(
  'appointment/fetchServices',
  async (_, { getState }) => {
    const state = getState()

    const currentData = state.appointment.currentData

    const response = await appointmentApi.getServices({
      cityId: currentData.city.id, categoryId: currentData.category.id 
    })

    return response.data
  }
)

export const fetchMasters = createAsyncThunk(
  'appointment/fetchMasters',
  async (_, { getState }) => {
    const state = getState()

    const currentData = state.appointment.currentData

    const response = await appointmentApi.getMasters({ branchId: currentData.branch.id })
    
    return response.data
  }
)

export const fetchAvailableScreens = createAsyncThunk(
  'appointment/fetchAvailableScreens',
  async ({ navigate }, { dispatch }) => {
    const response = await appointmentApi.getAvailableSteps()
    const availableSteps = response.data.attributes.data.map(step => STEPS[step])

    dispatch(appointmentActions.setSteps([...availableSteps, STEPS.order]))
  }
)

export const fetchAvailableTimeSlots = createAsyncThunk(
  'appointment/fetchAvailableTimeSlots',
  async (_, { getState }) => {
    const state = getState()

    const currentData = state.appointment.currentData
    const response = await appointmentApi.getRecords({
      masterId: currentData?.master?.id, services: currentData.services.map(service => service.id) 
    })

    const records = response.data.records
    const sortedData = {}

    Object.keys(records).forEach((key) => {
      const existedDates = []

      sortedData[key] = records[key].filter(slot => {
        const existed = existedDates.includes(slot.from)
    
        if (!existed) {
          existedDates.push(slot.from)
    
          return true
        }
    
        return false
      })
    })
    
    return sortedData
  }
)

export const goToNextStep = createAsyncThunk(
  'appointment/goToNextStep', 
  async ({ navigate }, {
    dispatch, getState 
  }) => {
    const state = getState()

    const steps = state.appointment.steps
    const currentStep = state.appointment.currentStep

    navigate(steps[currentStep + 1].url)
    dispatch(appointmentActions.setCurrentStep(currentStep + 1))
  }
)

export const goToPrevStep = createAsyncThunk(
  'appointment/goToPrevStep', 
  async ({ navigate }, {
    dispatch, getState 
  }) => {
    const state = getState()

    const steps = state.appointment.steps
    const currentStep = state.appointment.currentStep

    navigate(steps[currentStep - 1].url)
    dispatch(appointmentActions.setCurrentStep(currentStep - 1))
  }
)

export const createOrder = createAsyncThunk(
  'appointment/createOrder', 
  async ({ userData }, { getState }) => {
    const state = getState()
    const userDataFromState = state.app.userData

    const data = state.appointment.currentData

    const user = data.type === 'move' ? userDataFromState : userData

    const formattedData = {
      from: data.timeSlot.id,
      master: data.master.id,
      service: data.services.map(service => service.id),
      branch: data.branch.id,
      user: {
        ...user,
        userId: window?.Telegram?.WebApp?.initDataUnsafe?.user?.id || 3
      }
    }

    if (data.recordId && data.type === 'move') {
      await appointmentApi.cancelRecord(data.recordId)
    }
    
    await appointmentApi.createRecord(formattedData)

    window?.Telegram?.WebApp?.close()
  }
)

export const repeatOrder = createAsyncThunk(
  'appointment/repeatOrder', 
  async ({
    record, navigate 
  }, {
    getState, dispatch 
  }) => {
    const state = getState()

    const steps = state.appointment.steps

    const indexOfStep = steps.indexOf(STEPS.calendar)

    const data = {
      master: record.master,
      services: record.services,
      branch: record.master.branch,
      city: record.master.branch.city,
      type: 'repeat'
    }

    dispatch(appActions.setUserData({
      name: record.user.username, phone: record.user.phone 
    }))
    dispatch(appointmentActions.updateCurrentData(data))
    dispatch(appointmentActions.setCurrentStep(indexOfStep))

    navigate(steps[indexOfStep].url)
  }
)

export const moveOrder = createAsyncThunk(
  'appointment/moveOrder', 
  async ({
    record, navigate 
  }, {
    getState, dispatch 
  }) => {
    const state = getState()

    const steps = state.appointment.steps

    const indexOfStep = steps.indexOf(STEPS.calendar)

    const data = {
      master: record.master,
      services: record.services,
      branch: record.master.branch,
      city: record.master.branch.city,
      type: 'move',
      recordId: record.id
    }

    dispatch(appActions.setUserData({
      name: record.user.username, phone: record.user.phone 
    }))
    dispatch(appointmentActions.updateCurrentData(data))
    dispatch(appointmentActions.setCurrentStep(indexOfStep))

    navigate(steps[indexOfStep].url)
  }
)

export const appointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    setCities:(state, action) => {
      state.cities = action.payload
    },
    setSteps: (state, action) => {
      state.steps = action.payload
    },
    setCategories:(state, action) => {
      state.categories = action.payload
    },
    setServices: (state, action) => {
      state.services = action.payload
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload
    },
    updateCurrentData: (state, action) => {
      state.currentData = {
        ...state.currentData, ...action.payload 
      }
    },
    resetState: (state) => {
      state.cities = []
      state.currentStep = 0
      state.steps = []
      state.branches = []
      state.categories = []
      state.services = []
      state.masters = []
      state.timeSlots = {}
      state.currentData = {}
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchCities.fulfilled, (state, action) => {
      state.cities = action.payload
    }),
    builder.addCase(fetchBranches.fulfilled, (state, action) => {
      state.branches = action.payload
    }),
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.categories = action.payload
    }),
    builder.addCase(fetchUserRecords.fulfilled, (state, action) => {
      state.userRecords = action.payload
    }),
    builder.addCase(fetchServices.fulfilled, (state, action) => {
      state.services = action.payload
    }),
    builder.addCase(fetchMasters.fulfilled, (state, action) => {
      state.masters = action.payload
    }),
    builder.addCase(fetchAvailableTimeSlots.fulfilled, (state, action) => {
      state.timeSlots = action.payload
    }),
    builder.addCase(fetchMainBannerInfo.fulfilled, (state, action) => {
      state.mainBanner = action.payload
    })
  }
})

export const appointmentActions = appointmentSlice.actions
export const appointmentReducer = appointmentSlice.reducer