import React from 'react'

import styled from 'styled-components'
import ReactModal from 'react-modal'

ReactModal.defaultStyles.overlay.zIndex = 1000
ReactModal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.4)'
ReactModal.defaultStyles.overlay.display = 'flex'
ReactModal.defaultStyles.overlay.justifyContent = 'center'
ReactModal.defaultStyles.overlay.alignItems = 'center'
ReactModal.setAppElement('body')

export const Modal = ({
  className = '', children, opened, onClose 
}) => {
  return (
    <StyledModal
      className={className}
      isOpen={opened}
      onRequestClose={onClose}
    >
      {children}
    </StyledModal>
  )
}

const StyledModal = styled(ReactModal)`
  width: 95%;
  max-width: 320px;
  display: flex;
  background: #FFFFFF;
  border-radius: 8px;
  outline: none;
`