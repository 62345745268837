import React, {
  useEffect, useState 
} from 'react'

import styled from 'styled-components'
import { BackButton } from 'src/features/appointment/BackButton'
import { Checkbox } from 'src/ui/Checkbox'
import { SearchInput } from 'src/features/appointment/SearchInput'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  getCurrentAppointmentDataSelector, getServicesSelector 
} from 'src/features/appointment/selectors'
import {
  appointmentActions, fetchServices, goToNextStep 
} from 'src/features/appointment/appointmentSlice'
import { OrderBtn } from 'src/features/basket/BasketBtn'
import { TopRow } from 'src/features/common/TopRow'

export const ServicesPage = ({ className = '' }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const options = getServicesSelector()
  const [selectedOptions, setSelectedOptions] = useState([])
  const data = getCurrentAppointmentDataSelector()

  const handleNextClick = () => {
    dispatch(appointmentActions.updateCurrentData({ services: selectedOptions.map(option => options.find(o => o.id === option)) }))
    dispatch(goToNextStep({ navigate }))
  }

  const handleOptionClick = (id) => {
    setSelectedOptions(state => {
      const indexOfOption = state.indexOf(id)

      if (indexOfOption !== -1) {
        return [...state.slice(0, indexOfOption), ...state.slice(indexOfOption + 1)]
      }

      return [...state, id]
    })
  }

  useEffect(() => {
    dispatch(fetchServices())
  }, [])

  const [search, setSearch] = useState('')

  const handleSearch = (search) => {
    setSearch(search)
  }

  return (
    <>
      <TopRow>
        <StyledSearchInput onSearch={handleSearch} />
      </TopRow>

      <ServicesPageBox className={className}>

        <Title>
          {data.category.attributes.name}
        </Title>

        {
          options.filter(option => option.attributes.name.toLowerCase().includes(search)).map(option => (
            <OptionRow
              key={option.id}
            >
              <OptionTop onClick={() => handleOptionClick(option.id)}>
                <OptionName>
                  {option.attributes.name}
                </OptionName>

                <StyledCheckbox
                  checked={selectedOptions.includes(option.id)}
                  onChange={() => handleOptionClick(option.id)}
                />
              </OptionTop>

              <OptionBottom>
                <Price>
                  {option.attributes.price} ₽
                </Price>

                <Time>
                  {option.attributes.duration} мин
                </Time>
              </OptionBottom>
            </OptionRow>
          ))
        }

        <OrderBtn
          text="Далее к выбору мастера"
          disabled={!selectedOptions.length}
          onClick={handleNextClick}
        />
      </ServicesPageBox>
    </>
  )
}

const ServicesPageBox = styled.div`
  min-height: 100vh;
  padding: 16px 0 110px;
`

const Title = styled.p`
  margin: 0 0 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: ${props => props.theme.text.main};
  padding-left: 16px;
`

const BranchCard = styled.div`
  width: 100%;
  background: white;
  border-radius: 8px;

  & ~ & {
    margin-top: 16px;
  }
`

const OptionRow = styled.div`
  min-height: 111px;
  margin-left: 16px;
  padding-top: 24px;

  & ~ & {
    border-top: 0.5px solid #C6C6C6;
  }
`

const OptionTop = styled.div`
  display: flex;
  padding-right: 16px;
`

const OptionName = styled.p`
  margin-right: 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: ${props => props.theme.text.main};
`

const OptionBottom = styled.div`
  margin-top: 16px;
  display: flex;
`

const Price = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #037EE5;
`

const Time = styled.p`
  margin-left: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #7E7E82;
`

const StyledCheckbox = styled(Checkbox)`
  margin-left: auto;
  width: 24px;
  height: 24px;
`

const StyledSearchInput = styled(SearchInput)`
  margin: 0 12px;
`