import React from 'react'

import { Modal } from 'src/ui/Modal'
import styled from 'styled-components'

export const DialogModal = ({
  className = '', title, text, opened, okText, cancelText, onOk, onCancel, onClose 
}) => {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
    >
      <DialogModalBox className={className}>
        <Title>
          {title}
        </Title>

        <Description>
          {text}
        </Description>
        
        {!!cancelText &&
          <Btn
            className='secondary'
            onClick={onCancel}
          >{cancelText}</Btn>
        }

        {!!okText &&
          <Btn
            onClick={onOk}
          >{okText}</Btn>
        }
      </DialogModalBox>
    </Modal>
  )
}

const DialogModalBox = styled.div`
  padding: 16px 16px 24px;
  width: 100%;
`

const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  border-bottom: 0.5px solid #C6C6C6;
  padding-bottom: 16px;
  padding-right: 16px;
  margin-right: -16px;
`

const Description = styled.div`
  margin: 0 auto 15px;
  min-height: 135px;
  max-width: 260px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`

const Btn = styled.button`
  width: 100%;
  height: 40px;
  background: #037EE5;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.4px;
  color: #FFFFFF;

  &.secondary {
    background: #7E7E82;
  }

  & ~ & {
    margin-top: 16px;
  }
`