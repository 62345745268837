import React, {
  useEffect, useState 
} from 'react'

import { format } from 'date-fns'
import { START_DATE } from '@datepicker-react/hooks'
import { useDispatch } from 'react-redux'
import {
  appointmentActions, createOrder, fetchAvailableTimeSlots, goToNextStep 
} from 'src/features/appointment/appointmentSlice'
import {
  getCurrentAppointmentDataSelector, getTimeSlotsSelector 
} from 'src/features/appointment/selectors'
import { OrderBtn } from 'src/features/basket/BasketBtn'
import Datepicker from 'src/ui/datepicker/Datepicker'
import styled from 'styled-components'
import { ru } from 'date-fns/locale'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { BackButton } from 'src/features/appointment/BackButton'
import { TopRow } from 'src/features/common/TopRow'

export const CalendarPage = ({ className = '' }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentData = getCurrentAppointmentDataSelector()

  const [datepickerState, setDatepickerState] = useState({
    startDate: null,
    endDate: null,
    focusedInput: START_DATE
  })

  const [currentSlot, setCurrentSlot] = useState(null)

  const timeSlots = getTimeSlotsSelector()

  const availableDates = Object.keys(timeSlots).map((key) => key)

  const handleNext = () => {
    dispatch(appointmentActions.updateCurrentData({ timeSlot: currentSlot }))

    if (currentData.type === 'move') {
      dispatch(createOrder({}))

      return
    }

    dispatch(goToNextStep({ navigate }))
  }

  const handleSetDatepickerState = (...args) => {
    setCurrentSlot(null)
    setDatepickerState(...args)
  }

  const handleBackButtonClick = () => {
    window.location.href = '/'
  }

  useEffect(() => {
    dispatch(fetchAvailableTimeSlots())
  }, [])

  const formattedDate = datepickerState.startDate && format(datepickerState.startDate, 'yyyy-MM-dd')

  return (
    <>
      <TopRow onBackButtonClick={['move', 'repeat'].includes(currentData.type) ? handleBackButtonClick : undefined}>
        <Title>
          Выбор времени 
        </Title>
      </TopRow>

      <CalendarPageBox className={className}>
        <Datepicker
          availableDates={availableDates}
          datepickerState={datepickerState}
          onSetState={handleSetDatepickerState}
        />

        <OrderBtn
          text={currentData.type === 'move' ? 'Все верно' : 'Продолжить'}
          disabled={!currentSlot}
          onClick={handleNext}
        />

        {
          !!formattedDate &&
        (
          <>
            <DayName>
              {format(datepickerState.startDate, 'dd MMMM', { locale: ru })}
            </DayName>

            <TimeSlotsBox>
              {timeSlots[formattedDate].map(slot => (
                <TimeSlot
                  key={slot.id}
                  onClick={() => setCurrentSlot(slot)}
                  className={classNames({ active: currentSlot?.id === slot.id })}
                >
                  {format(new Date(slot.from), 'HH:mm', { locale: ru })}
                </TimeSlot>
              ))}
            </TimeSlotsBox>
          </>
        )
        }
      </CalendarPageBox>
    </>
  )
}

const CalendarPageBox = styled.div`
  padding: 16px 16px 110px;
`

const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: ${props => props.theme.text.main};
`

const DayName = styled.p`
  margin-top: 32px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #037EE5;
`

const TimeSlotsBox = styled.div`
  margin: 24px auto 0;
  display: grid;
  grid-template-columns: repeat(4, 70px);
  grid-gap: 18px 16px;
  width: fit-content;
`

const TimeSlot = styled.div`
  border: 1px solid #C6C6C6;
  border-radius: 4px;
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: ${props => props.theme.text.main};

  &.active {
    background: #037EE5;
    color: white;
  }
`