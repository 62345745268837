import React from 'react'

import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import backImg from '@/images/icons/back.svg'

import { goToPrevStep } from './appointmentSlice'

export const BackButton = ({
  className = '', onClick 
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleClick = () => {
    if (onClick) {
      onClick()

      return
    }

    dispatch(goToPrevStep({ navigate }))
  }

  return (
    <BackButtonBox
      className={className}
      onClick={handleClick}
    >
      <Img src={backImg} />
    </BackButtonBox>
  )
}

const BackButtonBox = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  cursor: pointer;
  transition: all 0.4s linear;
  
  &:hover {
    filter: brightness(110%);
  }
  
  &:focus, &:active {
    filter: brightness(120%);
  }
`

const Img = styled.img`
  width: 24px;
  height: 24px;
`