import { useSelector } from 'react-redux'

export const getStepsSelector = () => {
  return useSelector(state => state.appointment.steps)
}

export const getCitiesSelector = () => {
  return useSelector(state => state.appointment.cities)
}

export const getCurrentAppointmentDataSelector = () => {
  return useSelector(state => state.appointment.currentData)
}

export const getBranchesSelector = () => {
  return useSelector(state => state.appointment.branches)
}

export const getCategoriesSelector = () => {
  return useSelector(state => state.appointment.categories)
}

export const getServicesSelector = () => {
  return useSelector(state => state.appointment.services)
}

export const getMastersSelector = () => {
  return useSelector(state => state.appointment.masters)
}

export const getTimeSlotsSelector = () => {
  return useSelector(state => state.appointment.timeSlots)
}

export const getMainBanner = () => {
  return useSelector(state => state.appointment.mainBanner)
}

export const getUserRecordsSelector = () => {
  return useSelector(state => state.appointment.userRecords)
}