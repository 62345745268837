import React from 'react'

import styled from 'styled-components'

import starImg from '@/images/icons/star.svg'
import starHalFilledImg from '@/images/icons/star-half-filled.svg'
import starFilledImg from '@/images/icons/star-filled.svg'

export const Rating = ({
  className = '', 
  rating 
}) => {
  const stars = Array.from({ length: 5 }).map((_, index) => {
    const number = index + 1

    if (number <= rating) {
      return 1
    }

    if (number > rating && number - rating <= 0.5) {
      return 0.5
    }

    return 0
  })

  return (
    <RatingBox className={className}>
      {stars.map((item, index) => {
        const src = item === 1 ? starFilledImg : item === 0.5 ? starHalFilledImg : starImg

        return (
          <StarImg
            src={src}
            key={index}
          />
        )
      })}
    </RatingBox>
  )
}

const RatingBox = styled.div`
  display: flex;
  align-items: center;
`

const StarImg = styled.img`
  width: 12px;
  height: 12px;
`