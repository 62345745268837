import React from 'react'

import styled from 'styled-components'
import classNames from 'classnames'

import checkImg from '@/images/icons/check-arrow.svg'

export const Checkbox = ({
  checked, onChange, name, className = '', label 
}) => (
  <CheckboxBox className={className}>
    <HiddenInput
      type="checkbox"
      checked={checked}
      name={name}
      onChange={onChange}
      className="hidden-input"
    />

    <IconBox className={classNames({ checked })}>
      {checked && <CheckedIcon src={checkImg} />}
    </IconBox>

    {!!label &&
      <Label>{label}</Label>
    }
  </CheckboxBox>
)

const CheckboxBox = styled.label`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
`

const HiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`

const IconBox = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #7E7E82;
  border-radius: 50%;
  background: transparent;

  &.checked {
    border-color: ${props => props.theme.text.tertiary};
  }
`

const CheckedIcon = styled.img`
  width: 8px;
  height: 6px;
`

const Label = styled.span`
  margin-left: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.2px;
  color: ${props => props.theme.text.main};

`