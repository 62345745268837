import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import {
  Routes, Route, useNavigate 
} from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { ScrollToTopWhenLocationChanged } from './features/app/ScrollToTopWhenLocationChanged'
import { GlobalStyle } from './ui/styled.global'
import {
  DARK_THEME, LIGHT_THEME 
} from './constants/themes'
import { CityPage } from './pages/City'
import { BranchPage } from './pages/Branch'
import { ServicesPage } from './pages/Services'
import { CategoriesPage } from './pages/Categories'
import { MastersPage } from './pages/Masters'
import { OrderPage } from './pages/Order'
import { CalendarPage } from './pages/Calendar'
import { fetchAvailableScreens } from './features/appointment/appointmentSlice'
import { HomePage } from './pages/Home'


function App() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const currentTheme = window.Telegram.WebApp.colorScheme === 'dark' ? DARK_THEME : LIGHT_THEME

  useEffect(() => {
    dispatch(fetchAvailableScreens({ navigate }))
  }, [])

  return (
    <ThemeProvider theme={currentTheme}>
      <ScrollToTopWhenLocationChanged>
        <Routes>
          <Route
            path="/"
            element={<HomePage />}
          />

          <Route
            path="/region"
            element={<CityPage />}
          />
          <Route
            path="/branch"
            element={<BranchPage />}
          />
          <Route
            path="/categories"
            element={<CategoriesPage />}
          />
          <Route
            path="/services"
            element={<ServicesPage />}
          />
          <Route
            path="/masters"
            element={<MastersPage />}
          />
          <Route
            path="/order"
            element={<OrderPage />}
          />

          <Route
            path="/calendar"
            element={<CalendarPage />}
          />
        </Routes>

        <GlobalStyle />
      </ScrollToTopWhenLocationChanged>
    </ThemeProvider>
  )
}

export default App
