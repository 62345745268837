import React from 'react'

import {
  useDatepicker, START_DATE 
} from '@datepicker-react/hooks'
import styled from 'styled-components'

import Month from './Month'
import DatepickerContext from './datepickerContext'

function Datepicker({
  unavailableDates, 
  availableDates = [],
  datepickerState,
  onSetState
}) {
  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths
  } = useDatepicker({
    startDate: datepickerState.startDate,
    endDate: datepickerState.startDate,
    focusedInput: datepickerState.focusedInput,
    onDatesChange: handleDateChange,
    numberOfMonths: 1,
    unavailableDates: unavailableDates || [],
  })

  function handleDateChange(data) {
    onSetState({
      ...datepickerState, ...data, endDate: data.startDate, focusedInput: START_DATE,
    })
  }

  return (
    <DatepickerContext.Provider
      value={{
        focusedDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isFirstOrLastSelectedDate,
        onDateSelect,
        onDateFocus,
        onDateHover
      }}
    >
      <MonthsBox>
        {activeMonths.map(month => (
          <Month
            key={`${month.year}-${month.month}`}
            year={month.year}
            month={month.month}
            firstDayOfWeek={firstDayOfWeek}
            onNext={goToNextMonths}
            onPrev={goToPreviousMonths}
            availableDates={availableDates}
          />
        ))}
      </MonthsBox>
    </DatepickerContext.Provider>
  )
}

const MonthsBox = styled.div`
  width: 100%;
  max-width: 340px;
  margin: 28px auto 0;
`

export default Datepicker
