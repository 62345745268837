import React, {
  useRef, useContext 
} from 'react'

import { useDay } from '@datepicker-react/hooks'
import styled from 'styled-components'
import classNames from 'classnames'
import { format } from 'date-fns'

import DatepickerContext from './datepickerContext'

function Day({
  day, date, availableDates 
}) {
  const dayRef = useRef(null)

  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover
  } = useContext(DatepickerContext)

  const {
    isSelected,
    isSelectedStartOrEnd,
    disabledDate,
    onClick,
    onKeyDown,
    onMouseEnter,
    tabIndex
  } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef
  })

  if (!day) {
    return <div />
  }

  return (
    <DayBtn
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      tabIndex={tabIndex}
      type="button"
      ref={dayRef}
      className={classNames({
        active: isSelected || isSelectedStartOrEnd, 
        disabled: disabledDate || !availableDates.includes(format(date, 'yyyy-MM-dd'))
      })}
    >
      <DayText>
        {day}
      </DayText>
    </DayBtn>
  )
}

const DayBtn = styled.button`
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;

  &.disabled {
    pointer-events: none;
  }
`

const DayText = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: ${props => props.theme.text.main};
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  ${DayBtn}.disabled & {
    color: rgba(126, 126, 130, 0.24);
  }

  ${DayBtn}.active & {
    background: #037EE5;
    color: white;
  }
`

export default Day
