import React, { useEffect } from 'react'

import styled from 'styled-components'
import arrowImg from 'src/images/icons/arrow-right.png'
import { BackButton } from 'src/features/appointment/BackButton'
import { useDispatch } from 'react-redux'
import {
  appointmentActions, fetchBranches, goToNextStep 
} from 'src/features/appointment/appointmentSlice'
import { getBranchesSelector } from 'src/features/appointment/selectors'
import { useNavigate } from 'react-router-dom'
import { TopRow } from 'src/features/common/TopRow'

export const BranchPage = ({ className = '' }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const branches = getBranchesSelector()

  const handleOptionClick = (branch) => {
    dispatch(appointmentActions.updateCurrentData({ branch }))
    dispatch(goToNextStep({ navigate }))
  }

  useEffect(() => {
    dispatch(fetchBranches())
  }, [])

  return (
    <>
      <StyledTopRow>
        <Title>
          Выбор филиала 
        </Title>
      </StyledTopRow>
        
      <BranchPageBox className={className}>
        {
          branches.map(branch => (
            <BranchCard key={branch.id}>
              <MapBox src={branch.attributes?.images?.data[0]?.attributes?.url} />
              <OptionRow onClick={() => handleOptionClick(branch)}>
                <OptionLeft>
                  <BranchName>
                    {branch.attributes.name}
                  </BranchName>
                  <Address>
                    {branch.attributes.address}
                  </Address>
                </OptionLeft>

                <ArrowImg src={arrowImg} />
              </OptionRow>
            </BranchCard>
          ))
        }
      
      </BranchPageBox>
    </>
  )
}

const BranchPageBox = styled.div`
  background: ${props => props.theme.backgrounds.secondary};
  min-height: 100vh;
  padding: 16px 16px 110px;
`

const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.2px;
  color: ${props => props.theme.text.main};
`

const BranchCard = styled.div`
  width: 100%;
  background: ${props => props.theme.backgrounds.main};;
  border-radius: 8px;

  & ~ & {
    margin-top: 16px;
  }
`

const MapBox = styled.img`
  width: 100%;
  height: 144px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
`

const OptionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 16px;
  height: 72px;

  & ~ & {
    border-top: 0.33px solid rgba(60, 60, 67, 0.29);
  }
`

const OptionLeft = styled.div``

const BranchName = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: ${props => props.theme.text.main};
`

const Address = styled.p`
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #7E7E82;
`

const ArrowImg = styled.img`
  margin-right: 16px;
  width: 7px;
  height: 12px;
`

const TopRowEmptyBox = styled.div`
  width: 32px;
  height: 32px;
`

const StyledTopRow = styled(TopRow)`
  background: ${props => props.theme.backgrounds.secondary};
`